import React, { useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import style from "../css/hero.module.css"

// components
import BlogFooter from "../components/blog/BlogFooter"

// importing images
import hostparty from "../images/blog/host.png"
import invite from "../images/blog/create.png"
import share from "../images/blog/share.png"

const CoronaBlog = () => {
  useEffect(() => {
    if (window.analytics) {
      window.analytics.track("Visited Corona Guide")
    }
  }, [])

  return (
    <PageWrapper>
      <Wrapper>
        <BlogHeader>
          <Yellow>Guide:</Yellow> Keep your party Corona-safe😷
        </BlogHeader>
        <Teaser>
          Corona is some bullshit. We can all agree on that. But by taking
          responsibility, we can get back to 2019 conditions even faster.
          Therefore, we have made this guide on how to “Keep your party
          Corona-safe”
        </Teaser>

        <Section>
          <HeaderContainer>
            <Header>Planning🗓</Header>
            <YellowBar />
          </HeaderContainer>
          <Paragraph>
            Every party begins with a plan. Here you have to keep 2 things in
            mind: Equipment and a corona-safe location.
          </Paragraph>

          <Paragraph>
            <BoldSpan>Equipment💧:</BoldSpan> There are 2 essential things you
            NEED to buy before hosting a Corona-safe party:
            <br />
            <br />
            <UList>
              <ListItem>Lots of sanitizer</ListItem>
              <ListItem>Lots of plastic cups</ListItem>
              <ListItem>
                Tip: We can’t officially recommend it, but a little alcohol
                doesn’t destroy anything - just try to hold it on a minimum;)
              </ListItem>
            </UList>
          </Paragraph>

          <Paragraph>
            <BoldSpan>Corona Safe Location😷:</BoldSpan>Location is key. Here we
            have listed a few rules to follow:
            <br />
            <br />
            <UList>
              <ListItem>
                Each guest must have at least 4 sqm. space. Ask your mom/dad how
                big the house is and invite your friends based on that.
              </ListItem>
              <ListItem>Minimize the guest list.</ListItem>
            </UList>
            Tip: If there isn’t enough room for everybody, then split the guest
            list up and hold a party again next weekend
            <span role="img" aria-label="wink">
              😉
            </span>
          </Paragraph>
        </Section>

        <Section>
          <HeaderContainer>
            <Header>Invite👥</Header>
            <YellowBar />
          </HeaderContainer>
          <Paragraph>
            It isn’t always easy to keep track of how many people who are
            coming. Not everybody answers "Yes/No", and half of the people bring
            an extra friend. While it’s difficult to avoid, Queue at least makes
            it easier and more manageable.
            <br />
            <br />
            <StyledLink to="/invite-guide">
              <Button>Read More: Send Invite</Button>
            </StyledLink>
            <br />
            With a Queue invite you can include a few rules in the party
            description. Then you will avoid having to shout everbody up at the
            party. We recommend these here:
            <br />
            <br />
            <UList>
              <ListItem>Everybody sanitizes when arriving</ListItem>
              <ListItem>
                Everybody takes off their shoes when being indoor
              </ListItem>
              <ListItem>Everybody brings their own alcohol</ListItem>
            </UList>
          </Paragraph>
        </Section>

        <Section>
          <HeaderContainer>
            <Header>The Party🎉</Header>
            <YellowBar />
          </HeaderContainer>
          <Paragraph>
            Now it’s time to party. 4 things to keep in mind:
            <br />
            <br />
            <UList>
              <ListItem>Hide the beer pong</ListItem>
              <ListItem>Don’t drink of the same cups</ListItem>
              <ListItem>Sanitize (at least when guests arrive)</ListItem>
              <ListItem>
                And then the hardest of them all: Try not to drink as much
                alcohol
                <span role="img" aria-label="wink">
                  🥴
                </span>
              </ListItem>
            </UList>
            But what is a party without great music. With Queue, everybody can
            add and upvote songs from their own phone. Which means that
            everybodys favorite music will play wihtout everybody touching the
            same dirty phone.
            <br />
            <br />
            <StyledLink to="/music-guide">
              <Button>Read more: Start the music</Button>
            </StyledLink>
          </Paragraph>
          <Paragraph>
            As a brand-new feature to the Queue app, you can take and share an
            instant party picture to your local Queue nightlife and meet new
            people in your local area through our ‘Nightlife Feed’.
            <br />
            <br />
            <StyledLink to="/nightlife-share">
              <Button>Read more: Share a picture</Button>
            </StyledLink>
          </Paragraph>
        </Section>

        <Section>
          <HeaderContainer>
            <Header>The day after🥱</Header>
            <YellowBar />
          </HeaderContainer>
          <Paragraph>
            There is nothing worse than waking up to the smell of beer and
            vodka. 3 things to remember when cleaning up:
            <br />
            <br />
            <UList>
              <ListItem>Wear a pair of gloves</ListItem>
              <ListItem>
                Get rid of the cans and bottles ASAP (minimize the stench)
              </ListItem>
              <ListItem>
                Sanitize all horizontal surfaces (except the floor;)
              </ListItem>
            </UList>
            <br />
            <br />
            And that should be the whole thing. We hope you enjoyed reading. Tag{" "}
            <NormalLinkStyled
              href="https://www.instagram.com/queue_dk/"
              target="_blank"
            >
              @Queue_dk
            </NormalLinkStyled>{" "}
            when hosting your Corona-save party.
            <br />
            <br />
            We hope you get a good and safe party out there!
            <br />
            <BoldSpan>/Queue-teamet</BoldSpan>
          </Paragraph>
        </Section>
        <BlogFooter />
      </Wrapper>
    </PageWrapper>
  )
}

export default CoronaBlog

// Page Wrapper
const PageWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
`

// Content wrapper
const Wrapper = styled.div`
  max-width: 700px;
  height: auto;

  background-color: inherit;

  margin: 2.5rem 2.5rem 0 2.5rem;
`

// Blog header and subheader
const BlogHeader = styled.h1`
  font-family: "Avenir";
  font-size: 2rem;
  font-weight: 600;

  line-height: 1.3;

  margin-bottom: 0.7rem;

  @media (min-width: 500px) {
    font-size: 2.5rem;
  }

  @media (min-width: 650px) {
    font-size: 2.8rem;
  }
`

const Yellow = styled.span`
  color: #fdde68;
`

const Teaser = styled.p`
  font-family: "Avenir";
  font-size: 1.3rem;
  font-style: italic;
  line-height: 1.2;

  margin-bottom: 3rem;

  @media (min-width: 500px) {
    font-size: 1.4rem;
  }

  @media (min-width: 650px) {
    font-size: 1.6rem;
  }
  @media (min-width: 750px) {
    font-size: 1.8rem;
  }
`

// -------------

// Section styles
const Section = styled.section`
  max-width: inherit;
  min-height: 400px;

  height: auto;

  margin-bottom: 2.5rem;

  display: flex;
  flex-direction: column;
`

// Sticking header and bar together
const HeaderContainer = styled.div`
  height: auto;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  margin-bottom: 1rem;
`

const Header = styled.h1`
  font-family: "Avenir";
  font-size: 1.7rem;
  font-weight: 700;

  line-height: 1;

  margin-bottom: 0.6rem;

  @media (min-width: 500px) {
    font-size: 2rem;
  }

  @media (min-width: 650px) {
    font-size: 2.2rem;
  }
`

const YellowBar = styled.div`
  width: 80px;
  height: 15px;

  background-color: #fdde68;

  @media (min-width: 500px) {
    width: 100px;
  }

  @media (min-width: 650px) {
    width: 115px;
  }
`

const Paragraph = styled.p`
  font-family: "Avenir";
  font-size: 1rem;
  font-style: normal;

  line-height: 1.3;

  margin-bottom: 1.5rem;

  @media (min-width: 500px) {
    font-size: 1rem;
  }

  @media (min-width: 650px) {
    font-size: 1.2rem;

    margin-bottom: 2rem;
  }

  @media (min-width: 750px) {
    font-size: 1.4rem;
  }
`

const BoldSpan = styled.span`
  font-weight: bold;

  font-family: "Avenir";
  font-size: 1rem;
  font-style: normal;

  line-height: 1.3;

  @media (min-width: 500px) {
    font-size: 1rem;
  }

  @media (min-width: 650px) {
    font-size: 1.2rem;

    margin-bottom: 2rem;
  }

  @media (min-width: 750px) {
    font-size: 1.4rem;
  }
`

const UList = styled.ul`
  margin-left: 3em;

  @media (min-width: 500px) {
    margin-left: 3em;
  }

  @media (min-width: 650px) {
    margin-left: 3em;
  }

  @media (min-width: 750px) {
    margin-left: 3em;
  }
`
const ListItem = styled.li`
  margin-bottom: 5px;
`
// -------------

// Link to next guide
const More = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
`

const BoldParagraph = styled.p`
  font-family: "Avenir";
  font-size: 1.2rem;

  line-height: 1.3;

  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 500px) {
    font-size: 1.4rem;
  }

  @media (min-width: 650px) {
    font-size: 1.6rem;
  }
`

const Button = styled.div`
  width: 200px;
  height: 40px;

  background-color: #fdde68;

  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);

  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 500px) {
    width: 250px;
    height: 45px;
  }

  @media (min-width: 650px) {
    width: 300px;
    height: 50px;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  font-family: "Avenir";
  font-size: 1.2rem;
  text-decoration: none;

  color: black;

  @media (min-width: 650px) {
    font-size: 1.4rem;
  }
`

const NormalLinkStyled = styled.a`
  font-family: "Avenir";
  font-size: 1.2rem;
  text-decoration: none;

  color: #016fb9;

  @media (min-width: 650px) {
    font-size: 1.4rem;
  }
  @media (min-width: 500px) {
    font-size: 1rem;
  }

  @media (min-width: 650px) {
    font-size: 1.2rem;

    margin-bottom: 2rem;
  }

  @media (min-width: 750px) {
    font-size: 1.4rem;
  }
`

const Hash = styled(NormalLinkStyled)``
